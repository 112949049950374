<template>
  <notification-template
    :key="`${notificationType}-${activeTemplateType}`"
    :notification-type="notificationType"
    :template-type="activeTemplateType"
  >
    <marketing-campaign-tabs
      slot="tabs"
      class="notification-template-tabs"
      :active-tab-name="activeTemplateType"
      :tab-options="notificationTabOptions"
      @select-tab="selectTemplateType"
    />
  </notification-template>
</template>

<script>
import NotificationTemplate from '@/modules/dashboard/components/message-templates/NotificationTemplate';
import MarketingCampaignTabs from '@/modules/dashboard/components/message-templates/MarketingCampaignTabs';
import TabInfo from '@/modules/dashboard/components/common/TabInfo';

import templateUnsavedChangesMixin from '@/modules/dashboard/components/mixins/templateUnsavedChangesMixin';

import {
  MESSAGE_TEMPLATE_TYPES,
  TEMPLATE_TYPE_NAME,
  NOTIFICATION_TEMPLATES_TYPES
} from '@/modules/dashboard/api/constants';

const NOTIFICATION_TYPES_WITHOUT_SMS_TEMPLATE = [
  NOTIFICATION_TEMPLATES_TYPES.INCOMPLETE_QUESTIONNAIRE,
  NOTIFICATION_TEMPLATES_TYPES.NEW_TREATMENT
];

const NOTIFICATION_TAB_OPTIONS_EMAIL_SMS_TEMPLATES = [
  new TabInfo(MESSAGE_TEMPLATE_TYPES.EMAIL, TEMPLATE_TYPE_NAME[MESSAGE_TEMPLATE_TYPES.EMAIL]),
  new TabInfo(MESSAGE_TEMPLATE_TYPES.SMS, TEMPLATE_TYPE_NAME[MESSAGE_TEMPLATE_TYPES.SMS])
];

const NOTIFICATION_TAB_OPTIONS_EMAIL_TEMPLATE = [
  new TabInfo(MESSAGE_TEMPLATE_TYPES.EMAIL, TEMPLATE_TYPE_NAME[MESSAGE_TEMPLATE_TYPES.EMAIL])
];

export default {
  name: 'NotificationTemplateTabs',
  components: { MarketingCampaignTabs, NotificationTemplate },
  mixins: [templateUnsavedChangesMixin],
  props: {
    notificationType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTemplateType: null
    };
  },
  computed: {
    notificationTabOptions() {
      return NOTIFICATION_TYPES_WITHOUT_SMS_TEMPLATE.includes(this.notificationType)
        ? NOTIFICATION_TAB_OPTIONS_EMAIL_TEMPLATE
        : NOTIFICATION_TAB_OPTIONS_EMAIL_SMS_TEMPLATES;
    }
  },
  created() {
    this.activeTemplateType = MESSAGE_TEMPLATE_TYPES.EMAIL;
  },
  async beforeRouteLeave(to, from, next) {
    const canContinue = await this.onTemplateClose();

    if (!canContinue) {
      next(false);

      return;
    }

    next();
  },
  methods: {
    selectTemplateType(type) {
      this.activeTemplateType = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-template-tabs {
  margin-bottom: 15px;
}
</style>
